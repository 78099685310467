import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react"
import { Employee } from "../../../models/companyModels";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ChangeEvent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../data/redux/hooks";
import { connectTraineeAndTrainer, disconnectTraineeAndTrainer } from "../../../data/redux/companySlice";

interface ConnectTraineeWithTrainerModalProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    selectedTrainee?: Employee,
    trainers?: Employee[],
    company_admins?: Employee[]
}

interface ConnectTrainerWithTraineeFormData {
    trainer_id: number | string,
    trainee_id?: number,
    reload?: boolean
}

const ConnectTraineeWithTrainerModal: React.FC<ConnectTraineeWithTrainerModalProps> = ({ isOpen, setIsOpen, selectedTrainee, trainers, company_admins }) => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => state.user)

    const { control, handleSubmit, reset } = useForm<ConnectTrainerWithTraineeFormData>({
        mode: "onTouched",
        reValidateMode: "onChange",
        defaultValues: {
            trainer_id: ""
        }
    });

    const onSubmit: SubmitHandler<ConnectTrainerWithTraineeFormData> = (data: ConnectTrainerWithTraineeFormData) => {
        if (data.trainer_id === "") {
            let reqData: ConnectTrainerWithTraineeFormData = {
                trainer_id: selectedTrainee?.trainers?.[0].id ? selectedTrainee?.trainers?.[0].id : "",
                trainee_id: selectedTrainee!.id
            }
            if (user && user.id === selectedTrainee?.trainers?.[0].id) {
                reqData.reload = true
            } else {
                reqData.reload = false
            }
            dispatch(disconnectTraineeAndTrainer(reqData))
        } else {
            data.trainee_id = selectedTrainee!.id
            if (user && (user.id === selectedTrainee?.trainers?.[0]?.id || user.id === data.trainer_id)) {
                data.reload = true
            } else {
                data.reload = false
            }
            dispatch(connectTraineeAndTrainer(data))
        }
        setIsOpen(false)
    }

    useEffect(() => {
        if (selectedTrainee && isOpen) {
            reset({
                trainer_id: (isOpen && selectedTrainee && selectedTrainee.trainers && selectedTrainee.trainers[0]) ? selectedTrainee.trainers[0].id : ""
            })
        }

    }, [selectedTrainee, isOpen, reset])

    return (
        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        Azubi einem Ausbilder zuweisen
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding">
                <p>Hier können Sie festlegen, welcher Ausbilder Ihren Azubi <b>{selectedTrainee?.first_name} {selectedTrainee?.name}</b> betreut.</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <IonItem>
                        {(trainers && company_admins) &&
                            <Controller
                                name="trainer_id"
                                control={control}
                                render={({ field }) => <IonSelect
                                    interfaceOptions={{className: "connectTraineeWithTrainerSelect"}}
                                    label="Ausbilder"
                                    labelPlacement="floating"
                                    cancelText="Abbrechen"
                                    onIonChange={(e: { detail: { value: string | number | ChangeEvent<Element>; }; }) => field.onChange(e.detail.value)}
                                    value={field.value}
                                >
                                    <IonSelectOption value="" style={{fontStyle: "italic"}}><i>Kein Ausbilder</i></IonSelectOption>
                                    {trainers.map((trainer) => {
                                        return (
                                            <IonSelectOption value={trainer.id} key={trainer.id}>{trainer.first_name} {trainer.name}</IonSelectOption>
                                        )
                                    })}
                                    {company_admins.map((company_admin) => {
                                        return (
                                            <IonSelectOption value={company_admin.id} key={company_admin.id}>{company_admin.first_name} {company_admin.name}</IonSelectOption>
                                        )
                                    })}
                                </IonSelect>}
                            />
                        }
                    </IonItem>
                </form>
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot="end" className="ion-padding">
                        <IonButton onClick={() => setIsOpen(false)}>Abbrechen</IonButton>
                        <IonButton type="submit" onClick={handleSubmit(onSubmit)} fill="solid" color={"primary"}>Speichern</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>

        </IonModal>
    )
}

export default ConnectTraineeWithTrainerModal;