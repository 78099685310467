import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonRange, IonRow, IonTitle, IonToolbar } from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import { useRef, useState } from "react"
import { secToHHMM, toSeconds } from "../../util/report-helper"
import { ReportEntry } from "../../models/reportModels"
import { QuillEditor } from "../../components"
import { Delta } from "quill/core"
import { isJsonString } from "../../util/helper"

import "./ReportBook.scss";

interface DayEntryDialogProps {
    isOpen: boolean,
    handleClose: () => void
    addCallback: (duration: string, activity: string) => void,
    entry?: ReportEntry,
    editCallback: (id: number, duration: string, activity: string) => void
}

const DayEntryDialog: React.FC<DayEntryDialogProps> = ({ isOpen, handleClose, addCallback, entry, editCallback }) => {
    const [range, setRange] = useState<number>(entry ? toSeconds(entry.duration) : 0)
    const [activity, setActivity] = useState<string>(entry ? entry.activity : "");
    const [isRequired, setIsRequired] = useState<boolean>();
    const quillRef = useRef();

    const handleRangeInputChange = (e: any) => {
        if (e.detail.value) {
            const hm = e.detail.value;
            const hma = hm.split(":");
            const seconds = hma[0] * 60 * 60 + hma[1] * 60;
            setRange(seconds)
        }
    }

    const handleConfirm = () => {
        let editorContent = JSON.stringify(activity)
        const formattedRange = secToHHMM(range);
        if (activity !== "") {
            if (entry) {
                editCallback(entry.id, formattedRange, editorContent)
            } else {
                addCallback(formattedRange, editorContent);
            }
            handleClose();
            setRange(0);
            setActivity("");
        } else {
            setIsRequired(true)
        }
    }
    
    const getDefaultValue = () => {
        if (entry && entry.activity) {
            if (isJsonString(entry.activity)) {
                return JSON.parse(entry.activity)
            } else {
                return new Delta().insert(entry.activity)
            }
        } else {
            return new Delta().insert("")
        }
    }

    const handleTextChange = (value) => {
        if(value !== "" && isRequired){
            setIsRequired(false)
        }
        setActivity(value)
    }

    return (
        <IonModal
            isOpen={isOpen}
            onDidDismiss={handleClose}
            key={entry?.id}
            className="dayEntryDialog"
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{entry ? "Eintrag bearbeiten" : "Eintrag hinzufügen"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={handleClose}>
                            <IonIcon icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonItem lines="none" className="dayEntryDialogItem">
                                <IonRange
                                    value={range}
                                    onIonInput={(e: any) => setRange(e.detail.value)}
                                    min={0}
                                    max={43200}
                                    step={300}
                                    aria-label="Dauer"
                                >
                                    <div slot="label">Dauer</div>
                                </IonRange>
                            </IonItem>
                            <IonItem className="dayEntryDialogItem">
                                <IonInput
                                    value={secToHHMM(range)}
                                    pattern="^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
                                    type="time"
                                    min="00:00"
                                    max="12:00"
                                    onIonChange={handleRangeInputChange}
                                    aria-label="Dauer"
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol size="12">
                            <div style={{marginTop: 10}}>
                                <IonLabel style={{marginBottom: 4}}>Tätigkeit</IonLabel>
                                <QuillEditor
                                    ref={quillRef}
                                    onTextChange={handleTextChange}
                                    defaultValue={getDefaultValue()}
                                />
                            </div>
                            <div className="errorText" style={{ paddingLeft: 16 }}>{isRequired ? "Textfeld darf nicht leer sein." : ""}</div>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
            <IonFooter>
                <IonToolbar >
                    <IonButtons slot="end">
                        <IonButton onClick={handleClose}>
                            abbrechen
                        </IonButton>
                        <IonButton fill="solid" color="primary" onClick={handleConfirm}>speichern</IonButton>
                    </IonButtons>
                </IonToolbar>

            </IonFooter>
        </IonModal>
    )
}

export default DayEntryDialog;