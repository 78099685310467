import { IonAccordion, IonAccordionGroup, IonAlert, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRippleEffect, IonRow, IonToast, useIonAlert } from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { ImageCropper, PageContainer, Tooltip } from "../../components";
import { addOutline, businessOutline, ellipsisVerticalOutline, imageOutline, informationCircleOutline, pencilOutline, peopleOutline, sendOutline, trashBinOutline, trashOutline } from "ionicons/icons";

import "./Company.scss"
import { useEffect, useState } from "react";
import MailInvitationModal from "./components/MailInvitationModal";
import { useAppDispatch, useAppSelector } from "../../data/redux/hooks";
import { changeCompanyLogo, deleteEmployee, getCompany } from "../../data/redux/companySlice";
import AddEmployeeModal from "./components/AddEmployeeModal";
import { CompanyToken, Department, Employee, Location, Trainee } from "../../models/companyModels";
import ConnectTraineeWithTrainerModal from "./components/ConnectTraineeWithTrainerModal";
import CreateLocationModal from "./components/CreateLocationModal";
import DeleteLocationAlert from "./components/DeleteLocationAlert";
import EditLocationModal from "./components/EditLocationAlert";
import CreateDepartmentAlert from "./components/CreateDepartmentModal";
import EditDepartmentAlert from "./components/EditDepartmentAlert";
import DeleteDepartmentAlert from "./components/DeleteDepartmentAlert";
import EditAzubiModal from "./components/EditAzubiModal";
import EditCompanyNameModal from "./components/EditCompanyNameModal";
import { format } from "date-fns";
import RemoveInvitationAlert from "./components/RemoveInvitationAlert";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";



const CompanyPreview: React.FC<RouteComponentProps> = () => {
    const [mailInivitationModalOpen, setMailInivitationModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { company, loading, success } = useAppSelector((state) => state.company)
    const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [deleteUserAlertOpen, setDeleteUserAlertOpen] = useState<boolean>(false)
    const { user } = useAppSelector(state => state.user)
    const [selectedUser, setSelectedUser] = useState<Employee>();
    const [connectEmployeesModalOpen, setConnectEmployeesModalOpen] = useState<boolean>(false)
    const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);
    const [deleteLocationModalOpen, setDeleteLocationModalOpen] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [editLocationOpen, setEditLocationOpen] = useState<boolean>(false);
    const [createDepartmentOpen, setCreateDepartmentOpen] = useState<boolean>(false);
    const [editDepartmentOpen, setEditDepartmentOpen] = useState<boolean>(false);
    const [deleteDepartmentOpen, setDeleteDepartmentOpen] = useState<boolean>(false);
    const [selectedDepartment, setSelectedDepartment] = useState<Department>();
    const [editAzubiOpen, setEditAzubiOpen] = useState<boolean>(false);
    const [selectedTrainee, setSelectedTrainee] = useState<Trainee>();
    const [editCompanyNameModal, setEditCompanyNameModalOpen] = useState<boolean>(false);
    const [removeInvitationAlertOpen, setRemoveInvitationAlertOpen] = useState<boolean>(false);
    const [selectedInvitation, setSelectedInvitation] = useState<CompanyToken>();
    const [logo, setLogo] = useState<any>();
    const [imageCropperOpen, setImageCropperOpen] = useState<boolean>(false)
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false)
    const [presentLocationHintAlert] = useIonAlert();


    useEffect(() => {
        dispatch(getCompany())
    }, [dispatch])

    const handleUserDelete = () => {
        if (selectedUser) {
            let reqData = {
                id: selectedUser.id,
                role: selectedRole
            }
            dispatch(deleteEmployee(reqData))
        }
    }

    const takePhoto = async () => {
        setIsSheetOpen(false)
        try {
            const capture = await Camera.getPhoto({
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Photos,
                quality: 100,
            });
            setLogo(capture.dataUrl)
            setImageCropperOpen(true)
        } catch (err) {
            console.log(err)
        }

    }

    const imageCropperCallback = async (img: string) => {
        let blob = await fetch(img).then(r => r.blob());
        dispatch(changeCompanyLogo({ id: company!.company.id, image: blob }));
        setImageCropperOpen(false)
    }

    const removeCompanyLogo = () => {
        dispatch(changeCompanyLogo({ id: company!.company.id }))
        setIsSheetOpen(false)
    }

    return (
        <IonPage>
            <PageContainer name="Unternehmen" backButton={false}>
                <>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle className='headline'>
                                            Unternehmen {company && "-"} {company?.company.name}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>


                        {/* Unternehmen bearbeiten */}
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Allgemeine Infos</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    {(company && company.company.image) &&
                                                        < div className="inner">
                                                            <IonImg
                                                                alt="Firmenlogo" src={company.company.image}
                                                            ></IonImg>
                                                        </div>
                                                    }
                                                    <IonItem button onClick={() => setIsSheetOpen(true)}>
                                                        <IonIcon slot="start" icon={imageOutline} />
                                                        <IonLabel>Logo bearbeiten</IonLabel>
                                                    </IonItem>
                                                    <IonItem button onClick={() => setEditCompanyNameModalOpen(true)}>
                                                        <IonIcon slot="start" icon={businessOutline} />
                                                        {/* <IonLabel>{company?.company.name}</IonLabel> */}
                                                        <IonInput label="Name" labelPlacement="floating" value={company?.company.name} readonly />
                                                        <IonIcon slot="end" icon={pencilOutline} color="primary" />
                                                    </IonItem>
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        {/* Azubi einladen */}
                        <IonRow>
                            <IonCol size="12">
                                <IonCard color="primary" type="button" onClick={() => setMailInivitationModalOpen(true)} className="buttonCard ion-activatable" >
                                    <IonRippleEffect></IonRippleEffect>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Azubi einladen
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent >
                                        <div className="sendMailCardContent">
                                            <div>Senden Sie Ihrem Azubi einen Einladungslink zu. So können Sie sich mit Ihrem Azubi vernetzen.</div>
                                            <div><IonIcon icon={sendOutline} /></div>
                                        </div>
                                    </IonCardContent>

                                </IonCard>
                            </IonCol>
                        </IonRow>



                        <IonRow>
                            {/* Personalleiter */}
                            <IonCol>
                                <IonCard style={{ marginBottom: 0 }}>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Personalleiter</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    <div style={{ textAlign: "right" }}>
                                                        <Tooltip content="Personalleiter hinzufügen">
                                                            <IonButton size="small" onClick={() => {
                                                                setAddModalOpen(true)
                                                                setSelectedRole("company_admin")
                                                            }}>
                                                                <IonIcon icon={addOutline} />
                                                            </IonButton>
                                                        </Tooltip>
                                                    </div>
                                                    <IonList>
                                                        {company && company.company_admins.map((admin) => {
                                                            return (
                                                                <IonItem key={admin.id} className="companyListItem">
                                                                    <IonLabel>{admin.first_name} {admin.name}</IonLabel>
                                                                    {(user && user.id !== admin.id) &&
                                                                        <>
                                                                            <div className="ion-hide-sm-down">
                                                                                <Tooltip content="löschen">
                                                                                    <IonButton
                                                                                        slot="end"
                                                                                        fill="clear"
                                                                                        onClick={() => { setSelectedUser(admin); setSelectedRole("company_admin"); setDeleteUserAlertOpen(true) }}
                                                                                    >
                                                                                        <IonIcon icon={trashOutline} />

                                                                                    </IonButton>
                                                                                </Tooltip>
                                                                            </div>

                                                                            <div className="ion-hide-sm-up">
                                                                                <IonButton fill="clear" color="dark" slot="end" id={`adminPopoverTrigger-${admin.id}`}>
                                                                                    <IonIcon icon={ellipsisVerticalOutline} />
                                                                                </IonButton>
                                                                                <IonPopover trigger={`adminPopoverTrigger-${admin.id}`} dismissOnSelect={true}>
                                                                                    <IonContent>
                                                                                        <IonList>
                                                                                            <IonItem button={true} onClick={() => { setSelectedUser(admin); setSelectedRole("company_admin"); setDeleteUserAlertOpen(true) }}>löschen</IonItem>
                                                                                        </IonList>
                                                                                    </IonContent>
                                                                                </IonPopover>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </IonItem>
                                                            )
                                                        })}
                                                    </IonList>
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Ausbilder */}
                            <IonCol size="12">
                                <IonCard style={{ marginTop: 0, marginBottom: 0 }}>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Ausbilder</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    <div style={{ textAlign: "right" }}>
                                                        <Tooltip content="Ausbilder hinzufügen">
                                                            <IonButton size="small" onClick={() => {
                                                                setAddModalOpen(true)
                                                                setSelectedRole("trainer")
                                                            }}>
                                                                <IonIcon icon={addOutline} />
                                                            </IonButton>
                                                        </Tooltip>
                                                    </div>
                                                    {/* <IonList> */}
                                                    {company && company.trainers.map((trainer) => {
                                                        return (
                                                            <IonItem key={trainer.id} className="companyListItem">
                                                                <IonLabel>{trainer.first_name} {trainer.name}</IonLabel>
                                                                <div className="ion-hide-sm-down">
                                                                    <Tooltip content="Ausbilder löschen">
                                                                        <IonButton slot="end" fill="clear" onClick={() => { setSelectedUser(trainer); setSelectedRole("trainer"); setDeleteUserAlertOpen(true) }}>
                                                                            <IonIcon icon={trashOutline} />
                                                                        </IonButton>
                                                                    </Tooltip>
                                                                </div>
                                                                {/* Responsive Button */}
                                                                <div className="ion-hide-sm-up">
                                                                    <IonButton fill="clear" color="dark" slot="end" id={`trainerPopoverTrigger-${trainer.id}`}>
                                                                        <IonIcon icon={ellipsisVerticalOutline} />
                                                                    </IonButton>
                                                                    <IonPopover trigger={`trainerPopoverTrigger-${trainer.id}`} dismissOnSelect={true}>
                                                                        <IonContent>
                                                                            <IonList>
                                                                                <IonItem button={true} >löschen</IonItem>
                                                                            </IonList>
                                                                        </IonContent>
                                                                    </IonPopover>
                                                                </div>
                                                            </IonItem>
                                                        )
                                                    })}
                                                    {/* </IonList> */}
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Azubis */}
                            <IonCol size="12">
                                <IonCard style={{ marginTop: 0, marginBottom: 0 }}>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Azubis</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    <div style={{ textAlign: "right" }}>
                                                        <Tooltip content="Azubi einladen">
                                                            <IonButton size="small" onClick={() => setMailInivitationModalOpen(true)}>
                                                                <IonIcon icon={addOutline} />
                                                            </IonButton>
                                                        </Tooltip>
                                                    </div>
                                                    <IonList>
                                                        <IonItemGroup>
                                                            {company && company.trainee.map((trainee) => {
                                                                return (
                                                                    <IonItem key={trainee.id} style={{ contain: "unset" }} className="companyListItem">
                                                                        <IonLabel>
                                                                            {trainee.first_name} {trainee.name}
                                                                            <p>{trainee.trainers.length === 1 ? `Ausbilder: ${trainee.trainers[0].first_name} ${trainee.trainers[0].name}` : " Kein Ausbilder zugewiesen"}</p>
                                                                        </IonLabel>
                                                                        <div className="ion-hide-sm-down">
                                                                            <Tooltip content="bearbeiten">
                                                                                <IonButton slot="end" fill="clear" id="editTraineeBtn" onClick={() => {
                                                                                    setSelectedTrainee(trainee);
                                                                                    setEditAzubiOpen(true);
                                                                                }}>
                                                                                    <IonIcon icon={pencilOutline} title="test" />
                                                                                </IonButton>
                                                                            </Tooltip>

                                                                            <Tooltip content="Ausbilder zuweisen">
                                                                                <IonButton slot="end" fill="clear" onClick={() => {
                                                                                    setSelectedUser(trainee);
                                                                                    setConnectEmployeesModalOpen(true);
                                                                                }}>
                                                                                    <IonIcon icon={peopleOutline} />
                                                                                </IonButton>
                                                                            </Tooltip>

                                                                            <Tooltip content="löschen">
                                                                                <IonButton slot="end" fill="clear" onClick={() => { setSelectedUser(trainee); setSelectedRole("trainee"); setDeleteUserAlertOpen(true) }}>
                                                                                    <IonIcon icon={trashOutline} />
                                                                                </IonButton>
                                                                            </Tooltip>
                                                                        </div>

                                                                        {/* Responsive Button */}
                                                                        <div className="ion-hide-sm-up">
                                                                            <IonButton fill="clear" color="dark" slot="end" id={`traineePopoverTrigger-${trainee.id}`}>
                                                                                <IonIcon icon={ellipsisVerticalOutline} />
                                                                            </IonButton>
                                                                            <IonPopover trigger={`traineePopoverTrigger-${trainee.id}`} dismissOnSelect={true}>
                                                                                <IonContent>
                                                                                    <IonList>
                                                                                        <IonItem button={true} onClick={() => {
                                                                                            setSelectedTrainee(trainee);
                                                                                            setEditAzubiOpen(true);
                                                                                        }}>bearbeiten</IonItem>
                                                                                        <IonItem button={true} onClick={() => {
                                                                                            setSelectedUser(trainee);
                                                                                            setConnectEmployeesModalOpen(true);
                                                                                        }}>Ausbilder zuweisen</IonItem>
                                                                                        <IonItem button={true} onClick={() => { setSelectedUser(trainee); setSelectedRole("trainee"); setDeleteUserAlertOpen(true) }}>entfernen</IonItem>
                                                                                    </IonList>
                                                                                </IonContent>
                                                                            </IonPopover>
                                                                        </div>

                                                                    </IonItem>
                                                                )
                                                            })}
                                                        </IonItemGroup>
                                                        {(company && company.tokens.length > 0) &&
                                                            <IonItemGroup style={{ marginTop: 20 }}>
                                                                <IonItemDivider>
                                                                    <IonLabel>Versendete Einladungen</IonLabel>
                                                                </IonItemDivider>
                                                                {company && company.tokens.map((token) => {
                                                                    return (
                                                                        <IonItem key={token.id} style={{ contain: "unset" }} className="companyListItem">
                                                                            <IonLabel>
                                                                                {token.email}
                                                                                <p>Eingeladen am: {format(new Date(token.updated_at), "dd.MM.yyyy")}</p>
                                                                            </IonLabel>
                                                                            <Tooltip content="entfernen">
                                                                                <IonButton slot="end" fill="clear" onClick={() => { setSelectedInvitation(token); setRemoveInvitationAlertOpen(true) }}>
                                                                                    <IonIcon icon={trashOutline} />
                                                                                </IonButton>
                                                                            </Tooltip>
                                                                        </IonItem>
                                                                    )
                                                                })}

                                                            </IonItemGroup>}



                                                    </IonList>
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Standorte */}
                            <IonCol size="12">
                                <IonCard style={{ marginTop: 0, marginBottom: 0 }}>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Standorte</IonLabel>
                                                </IonItem>
                                                <div slot="content">
                                                    <div style={{ textAlign: "right" }}>
                                                        <IonButton size="small" fill="clear" onClick={() => presentLocationHintAlert({
                                                            header: 'Hinweis',
                                                            message: 'Der Name des Standorts ist ein frei setzbares Textfeld. Ist Ihr Unternehmen eine Gruppe aus mehreren Firmen, dann können Sie hier als Standort-Name z.B. "Firma A GmbH - Standort B" eintragen.',
                                                            buttons: ['schließen'],
                                                        })}>
                                                            <IonIcon icon={informationCircleOutline} />
                                                        </IonButton>
                                                        <Tooltip content="Standort hinzufügen">
                                                            <IonButton size="small" onClick={() => setLocationModalOpen(true)}>
                                                                <IonIcon icon={addOutline} />
                                                            </IonButton>
                                                        </Tooltip>
                                                    </div>
                                                    <IonList>
                                                        {company && company.locations.map((location: Location) => {
                                                            return (
                                                                <IonItem key={location.id} className="companyListItem">
                                                                    <IonLabel>{location.name}</IonLabel>

                                                                    <div className="ion-hide-sm-down">
                                                                        <Tooltip content="bearbeiten">
                                                                            <IonButton slot="end" fill="clear"
                                                                                onClick={() => {
                                                                                    setSelectedLocation(location);
                                                                                    setEditLocationOpen(true)
                                                                                }}
                                                                            >
                                                                                <IonIcon icon={pencilOutline} />
                                                                            </IonButton>
                                                                        </Tooltip>

                                                                        <Tooltip content="löschen">
                                                                            <IonButton slot="end" fill="clear"
                                                                                onClick={() => {
                                                                                    setSelectedLocation(location);
                                                                                    setDeleteLocationModalOpen(true);
                                                                                }}
                                                                            >
                                                                                <IonIcon icon={trashOutline} />
                                                                            </IonButton>
                                                                        </Tooltip>
                                                                    </div>

                                                                    {/* Responsive Button */}
                                                                    <div className="ion-hide-sm-up">
                                                                        <IonButton fill="clear" color="dark" slot="end" id={`locationPopoverTrigger-${location.id}`}>
                                                                            <IonIcon icon={ellipsisVerticalOutline} />
                                                                        </IonButton>
                                                                        <IonPopover trigger={`locationPopoverTrigger-${location.id}`} dismissOnSelect={true}>
                                                                            <IonContent>
                                                                                <IonList>
                                                                                    <IonItem button={true} onClick={() => {
                                                                                        setSelectedLocation(location);
                                                                                        setEditLocationOpen(true)
                                                                                    }}>bearbeiten</IonItem>
                                                                                    <IonItem button={true} onClick={() => {
                                                                                        setSelectedLocation(location);
                                                                                        setDeleteLocationModalOpen(true);
                                                                                    }}>löschen</IonItem>
                                                                                </IonList>
                                                                            </IonContent>
                                                                        </IonPopover>
                                                                    </div>
                                                                </IonItem>
                                                            )
                                                        })}
                                                    </IonList>
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>

                            {/* Abteilungen */}
                            <IonCol size="12">
                                <IonCard style={{ marginTop: 0, marginBottom: 0 }}>
                                    <IonCardContent>
                                        <IonAccordionGroup className="companyAccordeon">
                                            <IonAccordion>
                                                <IonItem slot="header">
                                                    <IonLabel>Abteilungen</IonLabel>
                                                </IonItem>
                                                <div slot="content" >
                                                    <div style={{ textAlign: "right" }}>
                                                        <Tooltip content="Abteilung hinzufügen">
                                                            <IonButton size="small" onClick={() => setCreateDepartmentOpen(true)}>
                                                                <IonIcon icon={addOutline} />
                                                            </IonButton>
                                                        </Tooltip>
                                                    </div>
                                                    <IonList>
                                                        {company && company.departments.map((department) => {
                                                            return (
                                                                <IonItem key={department.id} className="companyListItem">
                                                                    <IonLabel>{department.name}</IonLabel>

                                                                    <div className="ion-hide-sm-down">
                                                                        <Tooltip content="bearbeiten">
                                                                            <IonButton slot="end" fill="clear" onClick={() => {
                                                                                setSelectedDepartment(department)
                                                                                setEditDepartmentOpen(true)
                                                                            }}>
                                                                                <IonIcon icon={pencilOutline} />
                                                                            </IonButton>
                                                                        </Tooltip>

                                                                        <Tooltip content="löschen">
                                                                            <IonButton slot="end" fill="clear" onClick={() => {
                                                                                setSelectedDepartment(department);
                                                                                setDeleteDepartmentOpen(true)
                                                                            }}>
                                                                                <IonIcon icon={trashOutline} />
                                                                            </IonButton>
                                                                        </Tooltip>
                                                                    </div>

                                                                    {/* Responsive Button */}
                                                                    <div className="ion-hide-sm-up">
                                                                        <IonButton fill="clear" color="dark" slot="end" id={`departmentPopoverTrigger-${department.id}`}>
                                                                            <IonIcon icon={ellipsisVerticalOutline} />
                                                                        </IonButton>
                                                                        <IonPopover trigger={`departmentPopoverTrigger-${department.id}`} dismissOnSelect={true}>
                                                                            <IonContent>
                                                                                <IonList>
                                                                                    <IonItem button={true} onClick={() => {
                                                                                        setSelectedDepartment(department)
                                                                                        setEditDepartmentOpen(true)
                                                                                    }}>bearbeiten</IonItem>
                                                                                    <IonItem button={true} onClick={() => {
                                                                                        setSelectedDepartment(department);
                                                                                        setDeleteDepartmentOpen(true)
                                                                                    }}>löschen</IonItem>
                                                                                </IonList>
                                                                            </IonContent>
                                                                        </IonPopover>
                                                                    </div>
                                                                </IonItem>
                                                            )
                                                        })}
                                                    </IonList>
                                                </div>
                                            </IonAccordion>
                                        </IonAccordionGroup>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    <EditCompanyNameModal
                        isOpen={editCompanyNameModal}
                        setIsOpen={setEditCompanyNameModalOpen}
                    />

                    <MailInvitationModal
                        isOpen={mailInivitationModalOpen}
                        setIsOpen={setMailInivitationModalOpen}
                    />

                    <RemoveInvitationAlert
                        isOpen={removeInvitationAlertOpen}
                        setIsOpen={setRemoveInvitationAlertOpen}
                        selectedInvitation={selectedInvitation}
                    />

                    <AddEmployeeModal
                        isOpen={addModalOpen}
                        setIsOpen={setAddModalOpen}
                        role={selectedRole}
                    />

                    <ConnectTraineeWithTrainerModal
                        isOpen={connectEmployeesModalOpen}
                        setIsOpen={setConnectEmployeesModalOpen}
                        selectedTrainee={selectedUser}
                        trainers={company?.trainers}
                        company_admins={company?.company_admins}
                    />

                    <EditAzubiModal
                        isOpen={editAzubiOpen}
                        setIsOpen={setEditAzubiOpen}
                        selectedTrainee={selectedTrainee}
                    />

                    {/* Location Actions */}
                    <CreateLocationModal
                        isOpen={locationModalOpen}
                        setIsOpen={setLocationModalOpen}
                    />
                    <DeleteLocationAlert
                        isOpen={deleteLocationModalOpen}
                        setIsOpen={setDeleteLocationModalOpen}
                        selectedLocation={selectedLocation}
                    />
                    <EditLocationModal
                        isOpen={editLocationOpen}
                        setIsOpen={setEditLocationOpen}
                        selectedLocation={selectedLocation!}
                    />

                    {/* Department Actions */}
                    <CreateDepartmentAlert
                        isOpen={createDepartmentOpen}
                        setIsOpen={setCreateDepartmentOpen}
                    />
                    <EditDepartmentAlert
                        isOpen={editDepartmentOpen}
                        setIsOpen={setEditDepartmentOpen}
                        selectedDepartment={selectedDepartment}
                    />
                    <DeleteDepartmentAlert
                        isOpen={deleteDepartmentOpen}
                        setIsOpen={setDeleteDepartmentOpen}
                        selectedDepartment={selectedDepartment}
                    />

                    {/* company slice */}
                    <IonToast
                        message={success ? success : ""}
                        color="success"
                        duration={3000}
                        isOpen={success !== undefined ? true : false}
                    />

                    <IonLoading
                        isOpen={loading}
                        message="Lädt..."
                    />

                    {/* Delete User Alert */}
                    <IonAlert
                        isOpen={deleteUserAlertOpen}
                        header={`Benutzer entfernen`}
                        message={`Sind Sie sicher, dass Sie <b>${selectedUser?.first_name} ${selectedUser?.name}</b> entfernen möchten?`}
                        buttons={[
                            {
                                text: "Abbrechen",
                                role: "cancel"
                            }, {
                                text: "Entfernen",
                                handler: handleUserDelete
                            },
                        ]}
                        onDidDismiss={() => setDeleteUserAlertOpen(false)}
                    >
                    </IonAlert>


                    {/* Company Logo */}
                    <IonModal className="changeProfileImageSheet" isOpen={isSheetOpen} onDidDismiss={() => setIsSheetOpen(false)} initialBreakpoint={1} breakpoints={[0, 1]}>
                        <div className="block ion-padding">
                            <IonList>
                                <IonItem button detail={false} onClick={takePhoto}>
                                    <IonIcon slot="start" icon={imageOutline} />
                                    <IonLabel>neues Logo hochladen</IonLabel>
                                </IonItem>
                                <IonItem button detail={false} onClick={removeCompanyLogo}>
                                    <IonIcon slot="start" icon={trashBinOutline} />
                                    <IonLabel>Logo entfernen</IonLabel>
                                </IonItem>
                            </IonList>
                        </div>
                    </IonModal>

                    <ImageCropper
                        isOpen={imageCropperOpen}
                        setIsOpen={setImageCropperOpen}
                        img={logo}
                        imageCropperCallback={imageCropperCallback}
                    />


                </>
            </PageContainer>
        </IonPage >
    )
}

export default CompanyPreview;